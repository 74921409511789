<template>
  <v-main class="page-login">
    <v-container fill-height align-content-space-around>
      <v-layout row wrap fill-height align-content-space-arround justify-center>
        <v-flex xs12>
          <header class="header-logo">
            <img
              src="../assets/i/logo_propesca_texto.svg"
              alt="Propesca"
              class="logo"
            />
          </header>
        </v-flex>
        <v-flex xs10>
          <img
            src="../assets/i/icons/whatsapp.svg"
            alt="Propesca"
            v-if="type_message_aux == 'WhatsApp'"
            class="icon_message"
          />
          <img
            src="../assets/i/icons/comment-sms-solid.svg"
            alt="Propesca"
            v-else
            class="icon_message"
          />
          <p class="headline mb-3">
            Introduzca la clave que hemos enviado por mensaje {{type_message_aux}}
          </p>
          <v-text-field
            class="tf-large tf-white"
            clearable
            name="pin"
            label=""
            :counter="6"
            single-line
            dark
            v-model="pin"
            prepend-icon="textsms"
            mask="######"
            inputmode="numeric"
            :error-messages="errors.pin"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-btn large light @click="requestToken()">Enviar</v-btn>
        </v-flex>
        <v-flex xs12 v-if="type_message_aux == 'WhatsApp' && show_reload">
          <p>¿No has recibido la clave?</p>
          <v-btn large light @click="forcedSMS()">
            <p class="text-wrap" style="width: max-content; margin: auto;">
              Solicitar SMS
            </p>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'login-pin',
  components: {},
  data () {
    return {
      show: false,
      pin: '',
      errors: {},
      type_message_aux: '',
      show_reload: false
    }
  },
  props: {
    type_message: {
      type: String,
      required: true
    }
  },
  methods: {
    validate () {
      this.errors = {}
      let valid = true
      if (this.pin.length !== 6) {
        this.errors.pin = 'El pin debe tener 6 digitos'
        valid = false
      }

      return valid
    },
    requestToken () {
      if (this.validate()) {
        const phone = this.$store.state.phone

        this.$api
          .pinLogin(phone, this.pin)
          .then((response) => {
            this.$router.push('/')
          })
          .catch((error) => {
            this.$store.dispatch('showGlobalModal', {
              title: 'Error',
              text: error.response.data.non_field_errors[0]
            })
          })
      }

      // this.$router.push('home')
    },
    forcedSMS () {
      const phone = this.$store.state.phone
      this.$api
        .pinRequest(phone, true)
        .then((response) => {
          this.type_message_aux = response.data.type_message
        })
        .catch((error) => {
          console.log('el error')
          console.log(error.response.status)
          this.$store.dispatch('showGlobalModal', {
            title: 'Error',
            text: 'Algo ha fallado'
          })
        })
    }
  },
  beforeMount () {
    this.type_message_aux = this.type_message
  },
  mounted () {
    if (!this.$store.state.phone) {
      this.$router.push('/login')
    }
    this.show = true
    setTimeout(() => { this.show_reload = true }, 50000)
  }
}
</script>
