import Vue from 'vue'
import Router from 'vue-router'
import Vuex from 'vuex'
import store from './store'
import LoginRequest from './views/LoginRequest.vue'
import LoginPin from './views/LoginPin.vue'
import Menu from './views/Menu.vue'
import AvisosView from './views/AvisosView.vue'
import AvisoDetailView from './views/AvisoDetailView.vue'
import ExpirationsView from './views/ExpirationsView.vue'
import ClosedSeasonsView from './views/ClosedSeasonsView.vue'
import VentasShipsView from './views/VentasShipsView.vue'
import VentasView from './views/VentasView.vue'
import ComprasAliasView from './views/ComprasAliasView.vue'
import ComprasView from './views/ComprasView.vue'
import SubastaPortsView from './views/SubastaPortsView.vue'
import SubastaView from './views/SubastaView.vue'
import TimeTablesView from './views/TimeTablesView.vue'
import TimeTablesDetailView from './views/TimeTablesDetailView.vue'
import QuotasShipsView from './views/QuotasShipsView.vue'
import GestcuotaDetailView from './views/GestcuotaDetailView.vue'
import MegacuotaDetailView from './views/MegacuotaDetailView.vue'
import SpeciesView from './views/SpeciesView.vue'
import SpecieDetailView from './views/SpecieDetailView.vue'
import TallasView from './views/TallasView.vue'

Vue.use(Vuex)
Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginRequest
    },
    {
      path: '/pin',
      name: 'login-pin',
      props: true,
      component: LoginPin
    },
    {
      path: '/',
      name: 'menu',
      component: Menu,
      meta: {
        auth: true
      }
    },
    {
      path: '/avisos/',
      name: 'avisos',
      component: AvisosView,
      meta: {
        auth: true
      }
    },
    {
      path: '/avisos/:id',
      name: 'aviso',
      props: true,
      component: AvisoDetailView,
      meta: {
        auth: true
      }
    },
    {
      path: '/caducidades/',
      name: 'caducidades',
      component: ExpirationsView,
      meta: {
        auth: true
      }
    },
    {
      path: '/vedas/',
      name: 'vedas',
      component: ClosedSeasonsView,
      meta: {
        auth: true
      }
    },
    {
      path: '/ventas/',
      name: 'ventasShips',
      component: VentasShipsView,
      meta: {
        auth: true
      }
    },
    {
      path: '/ventas/',
      name: 'ventas',
      props: true,
      component: VentasView,
      meta: {
        auth: true
      }
    },
    {
      path: '/compras/',
      name: 'comprasAlias',
      props: true,
      component: ComprasAliasView,
      meta: {
        auth: true
      }
    },
    {
      path: '/compras/port/alias',
      name: 'compras',
      props: true,
      component: ComprasView,
      meta: {
        auth: true
      }
    },
    {
      path: '/subasta/',
      name: 'subastaPorts',
      component: SubastaPortsView,
      meta: {
        auth: true
      }
    },
    {
      path: '/subasta/',
      name: 'subasta',
      props: true,
      component: SubastaView,
      meta: {
        auth: true
      }
    },
    {
      path: '/horarios/',
      name: 'horarios',
      component: TimeTablesView,
      meta: {
        auth: true
      }
    },
    {
      path: '/horarios/:id',
      name: 'horario',
      component: TimeTablesDetailView,
      props: true,
      meta: {
        auth: true
      }
    },
    {
      path: '/cuotas/',
      name: 'quotas',
      component: QuotasShipsView,
      meta: {
        auth: true
      }
    },
    {
      path: '/cuotas/?shipId=:shipId&stockId=:stockId',
      name: 'gestcuota',
      props: true,
      component: GestcuotaDetailView,
      meta: {
        auth: true
      }
    },
    {
      path: '/cuotas/?shipId=:shipId&megaquotaId=:megaquotaId',
      name: 'megacuota',
      props: true,
      component: MegacuotaDetailView,
      meta: {
        auth: true
      }
    },
    {
      path: '/species/',
      name: 'species',
      component: SpeciesView,
      meta: {
        auth: true
      }
    },
    {
      path: '/species/:id',
      name: 'specie',
      props: true,
      component: SpecieDetailView,
      meta: {
        auth: true
      }
    },
    {
      path: '/tallas/',
      name: 'tallas',
      component: TallasView,
      meta: {
        auth: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (!store.getters.authenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
