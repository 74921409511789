<template>
  <transition name="slide-down">
    <div v-if="show" id="splash">
      <div class="content">
        <div class="logo-wrapper">
          <img
            src="../assets/i/logo_propesca_texto_splash.svg"
            alt="Propesca"
            class="logo"
          />
        </div>
        <v-container id="official_logos">
          <v-row>
            <v-col><img src="../assets/i/images/logo_galp_vertical.png"></v-col>
            <v-col><img src="../assets/i/images/logo_galp.png"></v-col>
            <v-col><img src="../assets/i/images/logo_galp_costaluz.png"></v-col>
          </v-row>
          <v-row >
            <div class="text">Proyecto cofinanciado en un 85% por el Fondo Europeo Marítimo y de la Pesca</div>
          </v-row>
          <v-row>
            <v-col cols="4"><img src="../assets/i/images/logo_andalucia.png"></v-col>
            <v-col cols="7"><img src="../assets/i/images/logo_femp.jpg"></v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: ['time'],
  data () {
    return {
      show: 1
    }
  },
  mounted () {
    setTimeout(() => {
      this.show = false
    }, this.time)
  }
}
</script>
