<template>
  <div id="sidebar">
    <v-navigation-drawer app right v-model="drawer">
      <div class="sidebar-close" @click.stop="drawer = !drawer">
        <img
          src="../assets/i/icons/cross.svg"
          alt="Cerrar"
          class="icon-cerrar"
        />
      </div>

      <modules ref="modules"></modules>

      <div class="copyright-section">
      <div>By <span id="strong">Sealab Solutions</span> V.{{ appVersion }}</div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Modules from './Modules'
import { version } from '../../package'

export default {
  components: {
    modules: Modules
  },
  name: 'sidebarComponent',
  data () {
    return {
      appVersion: version

    }
  },
  computed: {
    drawer: {
      get () {
        return this.$store.state.drawer
      },
      set (value) {
        this.$store.commit('drawer', value)
      }
    }
  },
  methods: {}
}
</script>
