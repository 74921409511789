<template>
  <div>
    <loading-component></loading-component>
    <v-main class="page-expirations">
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-flex xs12 sm10>
            <v-container class="container-ship-expirations">
              <transition
                name="slideInUp"
                v-for="(expiration, key_expiration, index_expiration) in expirationsApi"
                :key="key_expiration"
              >
                <div>
                  <div class="container-expiration" v-on:click="show_more">
                    <v-row>
                      <v-col cols="2">
                        <v-btn color="primary" icon class="btn_show_more"
                          ><img
                            src="../assets/i/icons/arrow_down.svg"
                            class="icon-expiration"
                          />
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="6"
                        :class="{
                          expired:
                            expirationCheck(getOlderDate(expiration.ship_expirations)) ==
                            'Caducado',
                        }"
                      >
                        {{ expiration.expiration_details.name }}
                      </v-col>
                      <v-col
                        cols="4"
                        :class="{
                          expired:
                            expirationCheck(getOlderDate(expiration.ship_expirations)) ==
                            'Caducado',
                        }"
                      >
                        {{
                          customDateFormat(
                            getOlderDate(expiration.ship_expirations)
                          )
                        }}
                      </v-col>
                    </v-row>
                    <v-expand-transition>
                      <div class="expiration_details">
                        <div class="ship_expirations">
                          <div
                            name="slideInUp"
                            class="ship_expiration"
                            v-for="(ship_expiration, index_expiration_ship) in expiration.ship_expirations"
                            :key="index_expiration_ship"
                          >
                            <v-row no-gutters>
                              <v-col v-if="expiration.ship_expirations.length > 1" cols="4"> {{ expiration.expiration_details.name + " " + (index_expiration_ship+1) }}: </v-col>
                              <v-col v-else cols="4"> Caducidad: </v-col>
                              <v-col cols="8"
                                v-if="ship_expiration.expiration_date"
                                :class="{
                                expired:
                                  expirationCheck(ship_expiration.expiration_date) ==
                                  'Caducado',
                                }"
                              >
                                {{ customDateFormat(ship_expiration.expiration_date) }}
                              </v-col>
                              <v-col cols="8"
                                v-else
                              >
                                {{ "Falta por asignar" }}
                              </v-col>
                            </v-row>
                            <div v-if="expiration.expiration_details.expiration_type === 'balsa'">
                              <v-row v-if="ship_expiration.serial_number" no-gutters>
                                <v-col cols="4"> Número de serie: </v-col>
                                <v-col cols="8">
                                  {{ ship_expiration.serial_number }}
                                </v-col>
                              </v-row>
                              <v-row v-if="ship_expiration.zafa_expiration_date" no-gutters>
                                <v-col cols="4"> Zafa: </v-col>
                                <v-col cols="8"
                                :class="{
                                expired:
                                  expirationCheck(ship_expiration.zafa_expiration_date) ==
                                  'Caducado',
                                }"
                                >
                                  {{ customDateFormat(ship_expiration.zafa_expiration_date) }}
                                </v-col>
                              </v-row>
                            </div>
                            <div v-if="expiration.expiration_details.expiration_type === 'licencia'">
                              <v-row no-gutters>
                                <v-col cols="4"> Modalidad: </v-col>
                                <v-col cols="8">
                                  {{ ship_expiration.license_modality }}
                                </v-col>
                              </v-row>
                            </div>
                            <div v-if="expiration.expiration_details.expiration_type === 'formacion_basica'
                              || expiration.expiration_details.expiration_type === 'reconocimiento_medico'">
                              <v-row no-gutters>
                                <v-col cols="4"> Nombre: </v-col>
                                <v-col cols="8">
                                  {{ ship_expiration.person_name }}
                                </v-col>
                              </v-row>
                            </div>
                            <div v-if="expiration.expiration_details.expiration_type === 'certificado'">
                              <v-row no-gutters>
                                <v-col cols="4"> Tipo: </v-col>
                                <v-col cols="8">
                                  {{ ship_expiration.certificate_type }}
                                </v-col>
                              </v-row>
                              <v-row
                                no-gutters
                                align="center"
                                v-if="
                                  (expiration.expiration_details.renewal_url || searchActionCertificate(expiration, expiration.ship_expirations[0].certificate_type).action ||
                                    expiration.expiration_details.action)
                                "
                              >
                                <v-col cols="4"> Accion: </v-col>
                                <v-col cols="8">
                                  <a
                                    v-if="expiration.expiration_details.expiration_type === 'certificado'
                                      && expiration.expiration_details.renewal_url"
                                    :href="expiration.expiration_details.renewal_url"
                                    >{{
                                      searchActionCertificate(expiration, ship_expiration.certificate_type).action || expiration.expiration_details.action
                                    }}</a>
                                  <a
                                    v-else-if="expiration.expiration_details.renewal_url"
                                    :href="expiration.expiration_details.renewal_url"
                                    >{{
                                      expiration.expiration_details.action ||
                                      "Renovación a traves de este link"
                                    }}</a>
                                  <div v-else>
                                    {{ searchActionCertificate(expiration, ship_expiration.certificate_type).action || expiration.expiration_details.action }}
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                            <div v-if="expiration.expiration_details.expiration_type === 'radiobaliza'">
                              <v-row no-gutters>
                                <v-col cols="4"> Tipo: </v-col>
                                <v-col cols="8">
                                  {{ ship_expiration.radiobaliza_type }}
                                </v-col>
                              </v-row>
                              <v-row
                                no-gutters
                                align="center"
                                v-if="
                                  (expiration.expiration_details.renewal_url || searchActionRadiobaliza(expiration, expiration.ship_expirations[0].radiobaliza_type).action ||
                                    expiration.expiration_details.action)
                                "
                              >
                                <v-col cols="4"> Accion: </v-col>
                                <v-col cols="8">
                                  <a
                                    v-if="expiration.expiration_details.expiration_type === 'radiobaliza'
                                      && expiration.expiration_details.renewal_url"
                                    :href="expiration.expiration_details.renewal_url"
                                    >{{
                                      searchActionRadiobaliza(expiration, ship_expiration.radiobaliza_type).action || expiration.expiration_details.action
                                    }}</a>
                                  <a
                                    v-else-if="expiration.expiration_details.renewal_url"
                                    :href="expiration.expiration_details.renewal_url"
                                    >{{
                                      expiration.expiration_details.action ||
                                      "Renovación a traves de este link"
                                    }}</a>
                                  <div v-else>
                                    {{ searchActionRadiobaliza(expiration, ship_expiration.radiobaliza_type).action || expiration.expiration_details.action }}
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                            <v-row no-gutters v-if="shipsCount() > 1">
                              <v-col cols="4"> Barco: </v-col>
                              <v-col cols="8">
                                {{ ship_expiration.ship.name }}
                              </v-col>
                            </v-row>
                          </div>
                        </div>

                        <v-row
                          v-if="expiration.expiration_details.competent_administration"
                          no-gutters
                        >
                          <v-col cols="6"> Administración </v-col>
                          <v-col cols="6">
                            {{ expiration.expiration_details.competent_administration }}
                          </v-col>
                        </v-row>

                        <v-row
                          no-gutters
                          align="center"
                          v-if="
                            expiration.expiration_details.expiration_type !== 'certificado'  &&
                            expiration.expiration_details.expiration_type !== 'radiobaliza'  &&
                            (expiration.expiration_details.renewal_url || expiration.expiration_details.action)
                          "
                        >
                          <v-col cols="4"> Accion: </v-col>
                          <v-col cols="8">
                            <a
                              v-if="expiration.expiration_details.renewal_url"
                              :href="expiration.expiration_details.renewal_url"
                              >{{
                                expiration.expiration_details.action ||
                                "Renovación a traves de este link"
                              }}</a>
                            <div v-else>
                              {{
                                expiration.expiration_details.action ||
                                "Renovación a traves de este link" }}
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-expand-transition>
                  </div>
                  <div
                    v-if="index_expiration < Object.keys(expirationsApi).length-1"
                    class="separation_line"
                  ></div>
                </div>
              </transition>
              <v-container class="no_avisos" v-if="Object.keys(expirationsApi).length <= 0">
                No tienes caducidades
              </v-container>
            </v-container>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import LoadingComponent from '../components/LoadingComponent.vue'

export default {
  name: 'expirationsView',
  components: {
    LoadingComponent
  },
  data () {
    return {
      show: false,
      title: 'Mis avisos',
      page: 1,
      expirations: undefined
    }
  },
  computed: {
    expirationsApi () {
      let json = {}
      // Creacion de array con agrupación de las caducidades barco por nombre de caducidad
      if (this.$store.state.expirations && Array.isArray(this.$store.state.expirations)) {
        for (let index = 0; index < this.$store.state.expirations.length; index++) {
          const element = this.$store.state.expirations[index]
          if (json[element.expiration.name] === undefined) {
            json[element.expiration.name] = { 'expiration_details': '', 'ship_expirations': [] }
          }
          json[element.expiration.name].expiration_details = element.expiration
          json[element.expiration.name].ship_expirations.push(element)
        }
      }
      return json
    }
  },
  methods: {
    show_more (event) {
      document
        .getElementsByClassName('container-expiration')
        .forEach((element) => {
          if (event.target.closest('.container-expiration') !== element) {
            element.classList.remove('show_more_information')
          }
        })
      event.target
        .closest('.container-expiration')
        .classList.toggle('show_more_information')
    },
    shipsCount () {
      return this.$store.state.shipsUser.count
    },
    searchActionCertificate (expiration, certificateType) {
      let action = false
      if (certificateType && expiration.expiration_details.expiration_action_certificate.length > 0) {
        action = expiration.expiration_details.expiration_action_certificate.find(
          certificate => certificate.certificate_type === certificateType
        )
      }

      return action
    },
    searchActionRadiobaliza (expiration, radiobalizaType) {
      let action = false
      if (radiobalizaType && expiration.expiration_details.expiration_action_radiobaliza.length > 0) {
        action = expiration.expiration_details.expiration_action_radiobaliza.find(
          radiobaliza => radiobaliza.radiobaliza_type === radiobalizaType
        )
      }

      return action
    },
    expirationCheck (date) {
      var expirationYear = date.slice(0, 4)
      var expirationMonth = date.slice(5, 7)
      var expirationDay = date.slice(8, 10)
      var currentYearTime = new Date()
      var currentDate = new Date(
        currentYearTime.getFullYear(),
        currentYearTime.getMonth(),
        currentYearTime.getDate()
      ).getTime()
      var dateExpiration = new Date(
        expirationYear,
        expirationMonth - 1,
        expirationDay
      ).getTime()
      if (dateExpiration - currentDate <= 0) {
        return 'Caducado'
      } else if (dateExpiration - currentDate <= 1000 * 60 * 60 * 24 * 30) {
        return 'A punto de caducar'
      } else {
        return 'Vigente'
      }
    },
    customDateFormat (date) {
      var year = date.slice(0, 4)
      var month = date.slice(5, 7)
      var day = date.slice(8, 10)
      var expirationDate = '0/00/0000'
      var mesesAbreviados = [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic'
      ]
      var currentYear = new Date().getFullYear()
      if (year === currentYear.toString()) {
        expirationDate = day + ' ' + mesesAbreviados[month - 1] + '.'
      } else {
        expirationDate = day + '/' + month + '/' + year.slice(2, 4)
      }
      return expirationDate
    },
    getOlderDate (expirationsShip) {
      let date = null
      for (var i = 0; i < expirationsShip.length; i++) {
        var current = expirationsShip[i]
        if (date === null || current.expiration_date < date) {
          date = current.expiration_date
        }
        if (current.zafa_expiration_date && (date === null || current.zafa_expiration_date < date)) {
          date = current.zafa_expiration_date
        }
      }
      return date
    }
  },
  mounted () {
    this.$api.expirations().catch((error) => {
      if (error.response.status === 403) {
        this.$store.commit('token', null)
        this.$router.push('/login')
      }
    })
    this.$api.ships().catch((error) => {
      if (error.response.status === 403) {
        this.$store.commit('token', null)
        this.$router.push('/login')
      }
    })
    this.show = true
    this.$store.commit('pageTitle', 'Caducidades')
    this.$store.commit('pageIcon', 'expirations.svg')
    this.$gtag.pageview(this.$route)
  }
}
</script>
