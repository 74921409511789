var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading-component'),_c('v-main',{staticClass:"page-expirations"},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm10":""}},[_c('v-container',{staticClass:"container-ship-expirations"},[_vm._l((_vm.expirationsApi),function(expiration,key_expiration,index_expiration){return _c('transition',{key:key_expiration,attrs:{"name":"slideInUp"}},[_c('div',[_c('div',{staticClass:"container-expiration",on:{"click":_vm.show_more}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"btn_show_more",attrs:{"color":"primary","icon":""}},[_c('img',{staticClass:"icon-expiration",attrs:{"src":require("../assets/i/icons/arrow_down.svg")}})])],1),_c('v-col',{class:{
                        expired:
                          _vm.expirationCheck(_vm.getOlderDate(expiration.ship_expirations)) ==
                          'Caducado',
                      },attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(expiration.expiration_details.name)+" ")]),_c('v-col',{class:{
                        expired:
                          _vm.expirationCheck(_vm.getOlderDate(expiration.ship_expirations)) ==
                          'Caducado',
                      },attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.customDateFormat( _vm.getOlderDate(expiration.ship_expirations) ))+" ")])],1),_c('v-expand-transition',[_c('div',{staticClass:"expiration_details"},[_c('div',{staticClass:"ship_expirations"},_vm._l((expiration.ship_expirations),function(ship_expiration,index_expiration_ship){return _c('div',{key:index_expiration_ship,staticClass:"ship_expiration",attrs:{"name":"slideInUp"}},[_c('v-row',{attrs:{"no-gutters":""}},[(expiration.ship_expirations.length > 1)?_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(expiration.expiration_details.name + " " + (index_expiration_ship+1))+": ")]):_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" Caducidad: ")]),(ship_expiration.expiration_date)?_c('v-col',{class:{
                              expired:
                                _vm.expirationCheck(ship_expiration.expiration_date) ==
                                'Caducado',
                              },attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.customDateFormat(ship_expiration.expiration_date))+" ")]):_c('v-col',{attrs:{"cols":"8"}},[_vm._v(" "+_vm._s("Falta por asignar")+" ")])],1),(expiration.expiration_details.expiration_type === 'balsa')?_c('div',[(ship_expiration.serial_number)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" Número de serie: ")]),_c('v-col',{attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(ship_expiration.serial_number)+" ")])],1):_vm._e(),(ship_expiration.zafa_expiration_date)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" Zafa: ")]),_c('v-col',{class:{
                              expired:
                                _vm.expirationCheck(ship_expiration.zafa_expiration_date) ==
                                'Caducado',
                              },attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.customDateFormat(ship_expiration.zafa_expiration_date))+" ")])],1):_vm._e()],1):_vm._e(),(expiration.expiration_details.expiration_type === 'licencia')?_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" Modalidad: ")]),_c('v-col',{attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(ship_expiration.license_modality)+" ")])],1)],1):_vm._e(),(expiration.expiration_details.expiration_type === 'formacion_basica'
                            || expiration.expiration_details.expiration_type === 'reconocimiento_medico')?_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" Nombre: ")]),_c('v-col',{attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(ship_expiration.person_name)+" ")])],1)],1):_vm._e(),(expiration.expiration_details.expiration_type === 'certificado')?_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" Tipo: ")]),_c('v-col',{attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(ship_expiration.certificate_type)+" ")])],1),(
                                (expiration.expiration_details.renewal_url || _vm.searchActionCertificate(expiration, expiration.ship_expirations[0].certificate_type).action ||
                                  expiration.expiration_details.action)
                              )?_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" Accion: ")]),_c('v-col',{attrs:{"cols":"8"}},[(expiration.expiration_details.expiration_type === 'certificado'
                                    && expiration.expiration_details.renewal_url)?_c('a',{attrs:{"href":expiration.expiration_details.renewal_url}},[_vm._v(_vm._s(_vm.searchActionCertificate(expiration, ship_expiration.certificate_type).action || expiration.expiration_details.action))]):(expiration.expiration_details.renewal_url)?_c('a',{attrs:{"href":expiration.expiration_details.renewal_url}},[_vm._v(_vm._s(expiration.expiration_details.action || "Renovación a traves de este link"))]):_c('div',[_vm._v(" "+_vm._s(_vm.searchActionCertificate(expiration, ship_expiration.certificate_type).action || expiration.expiration_details.action)+" ")])])],1):_vm._e()],1):_vm._e(),(expiration.expiration_details.expiration_type === 'radiobaliza')?_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" Tipo: ")]),_c('v-col',{attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(ship_expiration.radiobaliza_type)+" ")])],1),(
                                (expiration.expiration_details.renewal_url || _vm.searchActionRadiobaliza(expiration, expiration.ship_expirations[0].radiobaliza_type).action ||
                                  expiration.expiration_details.action)
                              )?_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" Accion: ")]),_c('v-col',{attrs:{"cols":"8"}},[(expiration.expiration_details.expiration_type === 'radiobaliza'
                                    && expiration.expiration_details.renewal_url)?_c('a',{attrs:{"href":expiration.expiration_details.renewal_url}},[_vm._v(_vm._s(_vm.searchActionRadiobaliza(expiration, ship_expiration.radiobaliza_type).action || expiration.expiration_details.action))]):(expiration.expiration_details.renewal_url)?_c('a',{attrs:{"href":expiration.expiration_details.renewal_url}},[_vm._v(_vm._s(expiration.expiration_details.action || "Renovación a traves de este link"))]):_c('div',[_vm._v(" "+_vm._s(_vm.searchActionRadiobaliza(expiration, ship_expiration.radiobaliza_type).action || expiration.expiration_details.action)+" ")])])],1):_vm._e()],1):_vm._e(),(_vm.shipsCount() > 1)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" Barco: ")]),_c('v-col',{attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(ship_expiration.ship.name)+" ")])],1):_vm._e()],1)}),0),(expiration.expiration_details.competent_administration)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Administración ")]),_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(expiration.expiration_details.competent_administration)+" ")])],1):_vm._e(),(
                          expiration.expiration_details.expiration_type !== 'certificado'  &&
                          expiration.expiration_details.expiration_type !== 'radiobaliza'  &&
                          (expiration.expiration_details.renewal_url || expiration.expiration_details.action)
                        )?_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" Accion: ")]),_c('v-col',{attrs:{"cols":"8"}},[(expiration.expiration_details.renewal_url)?_c('a',{attrs:{"href":expiration.expiration_details.renewal_url}},[_vm._v(_vm._s(expiration.expiration_details.action || "Renovación a traves de este link"))]):_c('div',[_vm._v(" "+_vm._s(expiration.expiration_details.action || "Renovación a traves de este link")+" ")])])],1):_vm._e()],1)])],1),(index_expiration < Object.keys(_vm.expirationsApi).length-1)?_c('div',{staticClass:"separation_line"}):_vm._e()])])}),(Object.keys(_vm.expirationsApi).length <= 0)?_c('v-container',{staticClass:"no_avisos"},[_vm._v(" No tienes caducidades ")]):_vm._e()],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }