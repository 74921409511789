<template>
  <div class="main-menu">
    <div class="user-section">
      <img
        src="../assets/i/logo_propesca_texto.svg"
        alt="Icono Usuario"
        class="logo"
      />
    </div>

    <modules ref="modules"></modules>

    <div class="copyright_section">
      <div id="logos">
        <img class="logo_andalucia" src="../assets/i/images/logo_junta_andalucia_blanco.png" alt="Icono Usuario"/>
        <img class="logo_ue" src="../assets/i/images/logo_ue_blanco.png" alt="Icono Usuario"/>
      </div>
      <div id="copyright_text">By <span id="strong">Sealab Solutions</span> V.{{ appVersion }}</div>
    </div>
  </div>
</template>

<script>
import Modules from '../components/Modules'
import { version } from '../../package'

export default {
  components: {
    modules: Modules
  },
  name: 'main-menu',
  data () {
    return {
      title: 'Menú',
      ship_owner: '',
      association: '',
      appVersion: version
    }
  },
  mounted () {
    this.$api
      .modules()
      .then((response) => {
        this.ship_owner = response.data.ship_owner.name
        this.association = response.data.association.name
        this.$store.commit('association', this.association)
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
    this.$api
      .hidden_modules()
      .then((response) => {})
      .catch((error) => {
        if (error.response.status === 403) {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
    this.$store.commit('pageTitle', 'Menú')
  }
}
</script>
